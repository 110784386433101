.container9 {
    margin: auto;
    padding: 7rem;
    --tw-bg-opacity: 1;
    background-color: rgb(228 228 231 / var(--tw-bg-opacity));
    display: flex;
}

.content9 {
    padding: 30px;
    font-family: 'Verdana';
}

.content9 > *{
    padding-bottom: 40px;
    font-family: 'Verdana';
}

.contact9 {
    font-size: 1.25rem/* 20px */;
    line-height: 1.75rem/* 28px */;
    font-weight: 600;
    padding-left: 0.75rem/* 12px */;
    padding-right: 0.75rem/* 12px */;
    padding-top: 0.75rem;
    display: flex;
    align-items: center;
    padding-right: 1rem;
    justify-content: center;
}

@media screen and (max-width:850px){
    .container9 {
        display: block;
        padding: 20px;
    }

    .container9 > :nth-child(2) {
        padding-left: 100px;
    }

    .content9 > h1 {
        padding-top: 65px;
    }

    .content9 > * {
        padding-bottom: 20px;
    }

    .content9 {
        width: 100%;
        padding: 8px;
    }
    .contact9 > div{
        font-size: 18px;
        padding-left: 6px;
    }
    .contact9 > div > :nth-child(2){
        font-size: 10px;
        font-weight: bold;
    }
}
