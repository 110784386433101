.slider1 {
    display: flex;
    align-items: center;
    opacity: 0;
    transform: rotate(0deg);
    transition: 5s;
    width: 30%;
    height: 10%;
  }

  .blocks > img{
    width: 30%;
    padding-bottom: 30px;
    height: 10%;
  }
  
  .slider--rotate {
    opacity: 1;
    transform: rotate(360deg);
    transition: 5s;
    width: 30%;
    height: 10%;
  }

  .itm > * {
    padding: 15px;
  }

  .blocks {
    display: flex;
  }

  @media screen and (max-width:500px) {
      .blocks{
        width:100%;
      }
      .blocks > img{
        width: 30%;
        padding-top: 80px;
        height: 20%;
      }

      .slider1 {
        display: flex;
        align-items: center;
        opacity: 0;
        transform: rotate(0deg);
        transition: 5s;
        width: 30%;
        size: 20px;
      }
      
      .slider--rotate {
        opacity: 1;
        transform: rotate(360deg);
        transition: 5s;
        size: 20px;
      }

  }