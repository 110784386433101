.sld {
    margin: auto;
    padding: 7rem;
    --tw-bg-opacity: 1;
    background-color: rgb(228 228 231 / var(--tw-bg-opacity));
}

.imm {
    padding-left: 320px;
    padding-top: 80px;
}

.imms {
    padding-left: 320px;
}

.resp {
    font-size: 1.25rem/* 20px */;
    line-height: 1.75rem/* 28px */;
    font-weight: 600;
    padding-left: 0.75rem/* 12px */;
    padding-right: 0.75rem/* 12px */;
    padding-top: 0.75rem;
    display: flex;
    align-items: center;
    padding-right: 1rem;
    justify-content: center;
}

@media screen and (max-width:450px) {
    .sld {
        padding: 20px;
    }
    .resp > div{
        font-size: 18px;
        padding-left: 6px;
    }

}