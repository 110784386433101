.container8 {
    margin: auto;
    padding: 7rem;
    --tw-bg-opacity: 1;
    background-color: rgb(228 228 231 / var(--tw-bg-opacity));
}
.word {
    display: flex;
}

.content8{
    padding: 30px/* 52px */;
    font-family: 'Arial';
}

.content8 > * > *{
    padding-bottom: 30px;
    font-family: 'Arial';
}

.content8 > *{
    padding-bottom: 30px;
    font-family: 'Arial';
}

@media screen and (max-width:800px) {

    .container8 {
        display: block;
        padding: 20px;
    }
    .word{
        display: block;
    }

    .container8 > :nth-child(1){
        padding-top: 65px;
    }

    .content8 > :nth-child(2) {
        padding-left: 20px;
    }
}