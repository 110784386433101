.contact12 {
    font-size: 1.25rem/* 20px */;
    line-height: 1.75rem/* 28px */;
    font-weight: 600;
    padding-left: 0.75rem/* 12px */;
    padding-right: 0.75rem/* 12px */;
    padding-top: 0.75rem;
    display: flex;
    align-items: center;
    padding-right: 1rem;
    justify-content: center;
}

@media screen and (max-width:830px) {
    .contact12 > div{
        font-size: 16px;
        padding-left: 3px;
    }

    .contact12 > div > :nth-child(2){
        font-size: 10px;
        font-weight: bold;
    }
}