.container10 {
    margin: auto;
    padding: 7rem;
    --tw-bg-opacity: 1;
    background-color: rgb(228 228 231 / var(--tw-bg-opacity));
    display: flex;
}

.content10 {
    padding: 30px/* 52px */;
    font-family: 'Verdana';
}

.content10 > p{
    padding-bottom: 40px;
}

.content10 > * {
    padding: 30px;
}

.contact10 {
    font-size: 1.25rem/* 20px */;
    line-height: 1.75rem/* 28px */;
    font-weight: 600;
    padding-left: 0.75rem/* 12px */;
    padding-right: 0.75rem/* 12px */;
    padding-top: 0.75rem;
    display: flex;
    align-items: center;
    padding-right: 1rem;
    justify-content: center;
}

@media screen and (max-width:850px){
    .container10 {
        display: block;
        padding: 20px;
    }

    .content10 > h1 {
        padding-top: 65px;
    }

    .content10 > * {
        padding-bottom: 20px;
    }

    .content10 {
        width: 100%;
        padding: 8px;
    }
    .contact10 > div{
        font-size: 18px;
        padding-left: 6px;
    }

    .contact10 > div > :nth-child(2){
        font-size: 10px;
        font-weight: bold;
    }
}
