.slider {
  display: flex;
  align-items: center;
  margin: 20px;
  opacity: 0;
  transform: scale(65%);
  transition: 2s;
}

.slider--zoom {
  opacity: 1;
  transform: scale(100%);
  transition: 2s;
}

.slider__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
}

.slider__content > *{
  padding-bottom: 30px;
}

.slider__title {
  text-align: center;
  font-weight: bolder;
  font-size: 25px;
  line-height: 1;
  font-family: 'Segoe UI';
}

@media screen and (max-width: 800px) {
  .slider {
    flex-direction: column;
  }

  .slider__image {
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .slider {
    flex-direction: column;
  }

  .slider__image {
    width: 60%;
  }
}


