.containermis {
    margin: auto;
    padding: 7rem;
    --tw-bg-opacity: 1;
    background-color: rgb(228 228 231 / var(--tw-bg-opacity));
    display: flex;
}

@media screen and (min-width:450px) and (max-width: 850px) {
    .containermis {
        max-width: 834px;
        display: block;
        padding: 20px;
    }
}

@media screen and (max-width: 450px) {
    .containermis {
        max-width: 500px;
        display: block;
        padding: 20px;
    }

    .container > :nth-child(2) {
        padding-left: 60px;
        padding-top: 50px;
        width: 80%;
    }
}