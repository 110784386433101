.container {
    margin: auto;
    padding: 7rem;
    --tw-bg-opacity: 1;
    background-color: rgb(228 228 231 / var(--tw-bg-opacity));
    display: flex;
}

.containers {
    margin: auto;
    padding-top: 2rem;
    --tw-bg-opacity: 1;
    background-color: rgb(228 228 231 / var(--tw-bg-opacity));
    display: flex;
}
.write {
    padding: 2rem;
    font-family: 'Verdana';
    font-size: 1.125rem/* 18px */;
    line-height: 1.75rem/* 28px */;
    width: 66.666667%;
}

.write > * {
    padding: 10px;
}

.resp {
    font-size: 1.25rem/* 20px */;
    line-height: 1.75rem/* 28px */;
    font-weight: 600;
    padding-left: 0.75rem/* 12px */;
    padding-right: 0.75rem/* 12px */;
    padding-top: 0.75rem;
    display: flex;
    align-items: center;
    padding-right: 1rem;
    justify-content: center;
}

.notflip {
    display: none;
    width: 30%;
    justify-content: center;
    margin-left: 300px;
}

@media screen and (min-width:450px) and (max-width: 850px) {
    .container,.containers {
        max-width: 834px;
        display: block;
        padding: 20px;
    }

    .containers > :nth-child(2) {
        padding-left:250px;
        padding-top: 60px;
        width: 70%;
    }

    .container > :nth-child(2) {
        padding-left:250px;
        padding-top: 60px;
        width: 70%;
    }
    
    .write > * {
        padding-top: 30px;
    }

    .write {
        width: 100%;
        padding: 8px;
    }

    .write > div {
        text-align: center;
    }
    .resp > div{
        font-size: 18px;
        padding-left: 6px;
    }
    .flip {
        display: none;
    }

    .notflip{
        display: block;
    }
}

@media screen and (max-width: 450px) {
    .container {
        max-width: 500px;
        display: block;
        padding: 20px;
    }

    .containers {
        max-width: 500px;
        display: block;
        padding: 20px;
    }

    .write > * {
        padding-top: 30px;
    }

    .write {
        width: 100%;
        padding: 8px;
    }

    .write > div {
        text-align: center;
    }
    
    .resp > div{
        font-size: 18px;
        padding-left: 6px;
    }

    .container > :nth-child(2) {
        padding-left: 110px;
        padding-top: 60px;
        width: 80%;
    }

    .containers > :nth-child(2) {
        padding-left: 110px;
        width: 80%;
    }
    .flip {
        display: none;
    }

    .notflip{
        margin-left: 85px;
        width: 60%;
        display: block;
    }

    .resp > div > :nth-child(2){
        font-size: 10px;
        font-weight: bold;
    }
}