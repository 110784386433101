.form-container {
  margin: 100px auto;
  width: 1000px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  border-radius: 10px;
  height: 600px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.close-btn {
  position: absolute;
  top: 2%;
  right: 3%;
  font-size: 1.5rem;
  z-index: 1;
  color: #fff;
  cursor: pointer;
}

.form-content-left {
  background: linear-gradient(
    90deg,
    rgb(39, 176, 255) 0%,
    rgb(0, 232, 236) 100%
  );
  border-radius: 10px 0 0 10px;
  position: relative;
}

.elems {
  padding-top: 80px;
}

.elems > * {
  padding: 10px;
  margin-left: 20px;
}

.words > div {
  padding: 10px;
  margin-left: 140px;
  display: flex;
}

.words > p {
  padding-top: 50px;
  margin-left: 150px;
  text-align: center;
}

.words > p > *{
  padding: 10px;
}

.form-img {
  width: 20%;
  height: 20%;
  position: relative;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-img-2 {
  width: 60%;
  height: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-success {
  text-align: center;
  font-size: 24px;
  margin-top: 80px;
  color: #fff;
}

.form-content-right {
  border-radius: 0 10px 10px 0;
  position: relative;
  background: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%);
}

.form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form h1 {
  font-size: 1rem;
  text-align: start;
  width: 80%;
  margin-bottom: 1rem;
  color: #fff;
}

.form-inputs {
  margin-bottom: 0.5rem;
  width: 80%;
}

.form-inputs p {
  font-size: 0.8rem;
  margin-top: 0.5rem;
  color: #f00e0e;
}

.form-label {
  display: inline-block;
  font-size: 0.8rem;
  margin-bottom: 6px;
  color: #fff;
}

.form-input {
  display: block;
  padding-left: 10px;
  outline: none;
  border-radius: 2px;
  height: 40px;
  width: 100%;
  size: 100px;
  border: none;
}

.form-input::placeholder {
  color: #595959;
  font-size: 12px;
}

.form-input-btn {
  display: flex;  
  background: linear-gradient(
    90deg,
    rgb(39, 176, 255) 0%,
    rgb(0, 232, 236) 100%
  ); 
  width: 80%;
  height: 50px;
  margin-top: 10px;
  border-radius: 2px;  
  outline: none;
  border: none;
  justify-content: center;
}

.form-input-btn > *{
  color: #fff;
  font-size: 20px;
}

.form-input-btn > :nth-child(1) {
  padding-top: 10px;
}

.form-input-btn > :nth-child(2) {
  padding-top: 17px;
  padding-left: 6px;
}

.form-input-btn:hover {
  cursor: pointer;
  background: linear-gradient(
    90deg,
    rgb(39, 143, 255) 0%,
    rgb(12, 99, 250) 100%
  );
  transition: all 0.4s ease-out;
}

.form-input-login {
  font-size: 0.8rem;
  margin-top: 10px;
  color: #fff;
  width: 80%;
  text-align: center;
}

.form-input-login a {
  text-decoration: none;
  color: #27cdff;
  font-weight: 600;
}

.content11 {
    font-size: 1.25rem/* 20px */;
    line-height: 1.75rem/* 28px */;
    font-weight: 600;
    padding-left: 0.75rem/* 12px */;
    padding-right: 0.75rem/* 12px */;
    padding-top: 0.75rem;
    display: flex;
    align-items: center;
    padding-right: 1rem;
    justify-content: center;
}

@media screen and (max-width:800px){
  .form-container {
    display: block;
    width: 370.4px;
    border-radius: none;
    margin-top:20px;
    height: 820px;
  }

  .form-content-left {
    height: 440px;
    width: 370.4px;
    border-radius: 10px 10px 0px 0px;
  }

  .form-content-right {
    height: 380px;
    width: 370.4px;
    border-radius: 0px 0px 10px 10px;
  }

  .words > div {
    padding: 10px;
    margin-left: 20px;
    display: flex;
  }
  
  .words > p {
    padding-top: 50px;
    margin-left: 30px;
    text-align: center;
  }

  .content11 > div{
    font-size: 18px;
    padding-left: 6px;
  }


}

@media screen and (max-width:850px) {
  .form-container {
    display: block;
    width: 700px;
    margin-top:20px;
    height: 820px;
  }

  .form-content-left {
    height: 440px;
    width: 700px;
    border-radius: 10px 10px 0px 0px;
  }

  .form-content-right {
    height: 380px;
    width: 700px;
    border-radius: 0px 0px 10px 10px;
  }

  .words > div {
    padding: 10px;
    margin-left: 20px;
    display: flex;
  }
  
  .words > p {
    padding-top: 50px;
    margin-left: 30px;
    text-align: center;
  }

  .content11 > div{
    font-size: 18px;
    padding-left: 6px;
  }

  .form-content-left > img {
    width: 100px;
  }

  .form-success {
    margin-top: 0px
  }
}

@media screen and (max-width:600px) {
  .form-container {
    display: block;
    width: 400px;
    margin-top:20px;
    height: 820px;
  }

  .form-content-left {
    height: 420px;
    width: 400px;
    border-radius: 10px 10px 0px 0px;
  }

  .form-content-right {
    height: 400px;
    width: 400px;
    border-radius: 0px 0px 10px 10px;
  }

  .words > div {
    padding: 10px;
    margin-left: 20px;
    display: flex;
  }
  
  .words > p {
    padding-top: 50px;
    margin-left: 30px;
    text-align: center;
  }

  .content11 > div{
    font-size: 18px;
    padding-left: 6px;
  }

  .form-content-left > img {
    width: 100px;
  }

  .form-success {
    margin-top: 0px
  }
  .content11 > div > :nth-child(2){
    font-size: 10px;
    font-weight: bold;
}
}