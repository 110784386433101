.container1 {
    padding-top: 112px;
    padding-left: 112px;
    padding-right: 112px;
    padding-bottom: 60px;
    --tw-bg-opacity: 1;
    background-color: rgb(228 228 231 / var(--tw-bg-opacity));
    display: flex;
    margin: auto;
}

.content {
    padding: 2rem;
    font-family: 'Verdana';
    font-size: 1.125rem/* 18px */;
    line-height: 1.75rem/* 28px */;
}

.contact {
    font-size: 1.25rem/* 20px */;
    line-height: 1.75rem/* 28px */;
    font-weight: 600;
    padding-left: 0.75rem/* 12px */;
    padding-right: 0.75rem/* 12px */;
    padding-top: 0.75rem;
    display: flex;
    align-items: center;
    padding-right: 1rem;
    justify-content: center;
}

@media screen and (min-width: 450px) and (max-width: 850px) {
    .container1 {
        max-width: 834px;
        display: block;
        padding: 20px;
    }

    .content > h1 {
        padding-top: 65px;
        
    }

    .content {
        width: 100%;
        padding: 8px;
        font-size: 20px;
    }

    .content > div {
        text-align: start;
    }
    .contact > div{
        font-size: 18px;
        padding-left: 6px;
    }
}

@media screen and (max-width: 450px){
    .container1 {
        max-width: 500px;
        display: block;
        padding: 20px;
    }

    .content > h1 {
        padding-top: 65px;
        
    }

    .content {
        width: 100%;
        padding: 8px;
        font-size: 20px;
    }

    .content > div {
        text-align: start;
    }
    .contact > div{
        font-size: 18px;
        padding-left: 6px;
    }
    .contact > div > :nth-child(2){
        font-size: 10px;
        font-weight: bold;
    }
}