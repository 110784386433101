.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.slide {
  opacity: 0;
  transition-duration: 3s ease;

}

.slide.active {
  opacity: 1;
  transition-duration: 3s;
  transform: scale(1.08)
}
.App-link {
  color: #61dafb;
}

.texts {
  display: grid;
}

.sldr {
  position: relative;
  height: 66.666667%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-banner{
  display: flex;
  justify-content: center;
}

.section-banner > :nth-child(1){
  padding-top: 100px;
}

.itms {
  display: flex;
  align-items: center;
  
}

.mob {
  margin-right: 16px;
}

.mb {
  display: none;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width:800px) {
  .section-banner{
      display: block;
  }
  .section-banner > :nth-child(1){
    justify-content: center;
    padding-left: 30px;
    width: 90%;
  }

  .section-banner > :nth-child(2){
    justify-content: center;
    width: 90%;
  }

  .left,.right {
    display: none;
  }

  .sldr > * {
    justify-content: center;
  }


  .texts > :nth-child(1){
    width: 100%;
  }

}

@media screen and (min-width:850px) {
    .itms > ul > div{
      padding-right: 2px;
    }
    .mob {
      display: none;
    }
    .mb {
      display: flex;
    }

    .left {
        left: 10rem;
    }

    .right {
        right: 10rem;
    }
}